<template>
    <div class="items-content">        
        <div class="items-header">
            <div>
                <div class="title">Itens</div>
                <div class="toggle-select-wrapper">
                    <toggle-button
                        :width="35"
                        v-model="multiPages"
                        :disabled="loading"
                        :sync="true"
                        :height="22"
                        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                    />
                    <span>Selecionar de todas as páginas</span>
                </div>
            </div>
            <div class="actions">
                <Loading :class="{ icon: true, reload: true, loading }" @click="() => !loading && getItems()"/>
                <Download class="import-icon"  @click="$bvModal.show('import-items-modal')"/>
                <div 
                    v-if="selecteds.length" 
                    class="batch-delete-wrapper" 
                    v-b-tooltip.hover title="Excluir items"
                    @click="deleteSelecteds"
                >
                    <b-badge class="badge" variant="danger">{{ selecteds.length }}</b-badge>
                    <Delete class="icon" />
                </div>
                <b-button variant="primary" @click="$bvModal.show('items-modal')">Novo Item</b-button>
            </div>
        </div>

        <b-form-input 
            v-model="query" 
            placeholder="Pesquisar item por identificador, código ou nome" 
            @input="debounceQuery"
        />

        <!-- <Tab
            ref="item_tabs"
            :index="currentTabIndex"
            v-bind:keys="tabs"
            v-on:onChange="onChangeTab"
            id="item--tabs"
            class="patient-profile__tabs"
        > -->
            <TableItems 
                :items="items" 
                :selecteds="selecteds"
                @edit="editItem"
                @delete="deleteItem"
                @handlerMasterSelect="handlerMasterSelect"
                @handlerSelect="handlerSelect"
            />
        <!-- </Tab> -->

        <div class="pagination-position">
            <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="limit"
                first-number
                last-number
                size="sm"
            />
        </div>
        
        <ItemModal 
            :itemId="itemId"
            :types="types"
            @hidden="() => itemId = null"
            @saved="onSaved"
        />
    </div>
</template>
<script>
import api from '@items/api'
import { debounce } from 'lodash';
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),
        Download: () => import('@/assets/icons/download.svg'),
        Delete: () => import('@/assets/icons/delete.svg'),
        // Tab: () => import('@/components/TabPills'),
        ItemModal: () => import('@items/modals/ItemModal'),
        TableItems: () => import('@items/layouts/TableItems'),
    },
    data() {
        return {
            currentTabIndex: null,
            tabs: ['Procedimentos', 'Produtos', 'Outras despesas'],
            loading: false,
            clinic: getCurrentClinic(),
            multiPages: false,
            debounceQuery: null,
            query: null,
            page: 1,
            count: 0,
            limit: 0,
            itemId: null,
            items: [],
            selecteds: [],
            types: []
        }
    },
    mounted() {
        this.debounceQuery = debounce(this.getItems, 500)
        this.getItems()
    },
    methods: {
        onChangeTab(index) {
            this.currentTabIndex = index
        },
        getItems() {
            this.loading = true
            api.getItems(this.page, this.clinic.id, this.query)
                .then(({ data }) => {
                    this.count = data.total
                    this.limit = data.per_page
                    this.items = data.data
                })
                .finally(() => {
                    this.loading = false
                })
        },
        editItem(item) {
            this.itemId = item.id
            this.$bvModal.show('items-modal')
        },
        onSaved(item) {
            const index = this.items.findIndex(({ id }) => id === item.id)
            index >= 0 ?
                this.items.splice(index, 1, item) :
                this.items.push(item)
        },
        async deleteItem(item) {
            try {
                await api.deleteItem(item.id)
                this.$toast.success('Item excluído com sucesso')
                const index = this.items.findIndex(({ id }) => id === item.id)
                this.items.splice(index, 1)
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                
            }
        },
        deleteSelecteds() {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir os <span>${this.selecteds.length} itens</span> selecionados?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await api.deleteItems(this.selecteds)
                        this.$toast.success('Itens excluídos com sucesso')
                        this.selecteds.forEach(id => {
                            const index = this.items.findIndex(item => item.id === id)
                            this.items.splice(index, 1)
                            this.page !== 1 && (this.page = 1)
                        })
                        this.selecteds = []
                    } catch (error) {
                        this.$toast.error(error.message)
                    }
                }
            })
        },
        async handlerMasterSelect(checked) {
            if (!checked) {
                this.selecteds = []
            } else if (!this.multiPages) {
                this.selecteds = this.items.filter(el => el.clinic_id).map(({ id }) => id)
            } else {
                this.loading = true
                try {
                    const { data } = await api.getItemIds(this.clinic.id)
                    this.selecteds = data
                } catch (error) {
                    this.$toast.error(error.message)
                } finally {
                    this.loading = false
                }
            }
        },
        handlerSelect(item) {
            const index = this.selecteds.findIndex(id => id === item.id)
            index >= 0 ?
                this.selecteds.splice(index, 1) :
                this.selecteds.push(item.id)
        }
    },
    watch: {
        page() {
            this.getItems()
        }
    }
    
}
</script>
<style lang="scss" scoped>
.items-content {
    padding: 1rem;
    .toggle-select-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
    .items-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0;
        .title {
            font-family: "Red Hat Display";
            font-weight: 700;
            font-size: 24px;
            color: var(--dark-blue);
        }
        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            .import-icon {
                width: 24px;
                fill: var(--type-active);
                cursor: pointer;
            }
            .batch-delete-wrapper {
                position: relative;
                cursor: pointer;

                .badge {
                    position: absolute;
                    right: -6px;
                    top: -4px;
                }
            }
        }
    }
}
</style>